
import { defineComponent } from "vue";
import { Form, Field } from "vee-validate";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
// import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import { VueCookieNext } from "vue-cookie-next";
import { apiEndpoint } from "@/mixin/apiEndpoint";

export default defineComponent({
  mixins:[apiEndpoint],
  name: "new-certification-bill-adjustment",
  components: {
    Form,
    Field,
    Swal,
    Datatable,
  },
  data() {
    return {
       batch: {
        tranche_id:"",
        entity_id:"",
        bill_no:"",
        claim_no:2
      },
      entityInfos: [],
      allBillNo:[],
      tranches_info:[],
      empDistricts: [],
      showCourseNotice: false,

      tableHeader: [
        {
          name: "Actions",
          key: "actions",
          sortable: false,
          width: "150px",
        },
        {
          name: "Sl",
          key: "sl",
          sortable: true,
          width: "5px",
        },
        {
          name: "TI",
          key: "ti",
          sortable: true,
        },
        {
          name: "Course",
          key: "course",
          sortable: true,
        },
        {
          name: "BN",
          key: "bn",
          sortable: true,
        },
        {
          name: "EN #",
          key: "en",
          sortable: true,
        },

        {
          name: "AN #	",
          key: "an",
          sortable: true,
        },
        {
          name: "CN #",
          key: "cn",
          sortable: true,
        },
        {
          name: "Unit Cost",
          key: "unit_cost",
          sortable: true,
        },
        {
          name: "Pay %",
          key: "pay_percent",
          sortable: true,
        },
        {
          name: "Payment Amount",
          key: "payment_amount",
          sortable: true,
        },
        {
          name: "	Bill Amount",
          key: "bill_amount",
          sortable: true,
        },
        {
          name: "New Unit Cost	",
          key: "new_unit_cost",
          sortable: true,
        },
        {
          name: "New Payment Amount	",
          key: "new_payment_amount",
          sortable: true,
        },
        {
          name: "New Bill Amount	",
          key: "new_bill_amount",
          sortable: true,
        },
        {
          name: "Adj. Amount",
          key: "adj_amount",
          sortable: true,
        },
      ],
      tableData: [
        {
          sl: 1,
          ti: 5,
          course: 44,
          bn: "পাইথন এডভান্সড",
          en: "Python Advanced",
          an: "510232100",
          cn: "19861591906025850",
          unit_cost: 198,
          pay_percent: 87,
          payment_amount: 45,
          bill_amount: 78,
          new_unit_cost: 56,
          new_payment_amount: 858,
          new_bill_amount: 678,
          adj_amount: 78,
        },
        {
          sl: 2,
          ti: 5,
          course: 45,
          bn: "এসকিউএল কোর্স",
          en: "SQL Course",
          an: "510232100",
          cn: "19861591906025850",
          unit_cost: 198,
          pay_percent: 87,
          payment_amount: 45,
          bill_amount: 78,
          new_unit_cost: 56,
          new_payment_amount: 858,
          new_bill_amount: 678,
          adj_amount: 78,
        },
      ],
      loading: false,
      courseInfoData: false,
      
      showtrainingProviderNotice: false,
    };
  },
  async created() {
    await this.getTranches();
    await this.getAssociation();
    // await this.getEmploymentBillList();
  },
  methods: {
    async formSubmit() {
      //form submit
    },
      async getTranches() {
      await ApiService.get(this.VUE_APP_TRANCHE_LIST_API)
        .then((response) => {
          this.tranches_info = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getAssociation() {
      let entity_id = "";
      if (VueCookieNext.getCookie("_seip_entity_type") != 1004) {
        entity_id = VueCookieNext.getCookie("_entity_id");
      }
      await ApiService.get("entity/list?entity_id=" + entity_id)
        .then((response) => {
          this.entityInfos = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
     async getAllBillNo() {
      await ApiService.get("bill/claim_2/all_bill_no?entity_id=" + this.batch.entity_id+"&tranche_id="+ this.batch.tranche_id+"&active_status=2")
        .then((response) => {
          this.allBillNo = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    courseNotice() {
      this.showCourseNotice = true;
    },
  },
  setup() {
    const AssociationSchema = [];
    return {
      AssociationSchema,
    };
  },
});
